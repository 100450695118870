<template>
  <section>
    <a-row class="search-container" type="flex" justify="space-between">
      <a-form
        :form="enterForm"
        layout="inline"
        @submit="submitForm"
        class="m-t-14"
      >
        <a-form-item label="出入口名称">
          <a-input
            autocomplete="off"
            v-decorator="['doorwayName']"
            placeholder="请输入"
          />
        </a-form-item>

        <a-form-item label="出入口类型">
          <a-select
            class="search-input"
            placeholder="请选择"
            v-decorator="['doorwayType']"
          >
            <a-select-option value="0"> 人员出入 </a-select-option>
            <a-select-option value="1"> 车辆出入 </a-select-option>
          </a-select>
        </a-form-item>

        <a-form-item>
          <a-button type="primary" class="m-r-8" html-type="submit"
            >查询</a-button
          >
          <a-button class="m-r-8" @click="reset">重置</a-button>
        </a-form-item>
      </a-form>

      <a-button type="primary" class="m-t-18" @click="andEnter"
        >添加出入口</a-button
      >
    </a-row>

    <a-row type="flex" :gutter="20">
      <a-col
        :span="6"
        v-for="(item, key) in doorListData"
        :key="key"
        class="m-t-16"
      >
        <a-row class="list-card-device list-card-container">
          <a-row class="list-wrap">
            <a-row type="flex" justify="space-between">
              <a-col class="list-image m-r-10">
                <img :src="item.typeImg" alt="" />
              </a-col>
              <a-col style="width: 60%">
                <a-row
                  class="list-item"
                  style="font-size: 16px; font-weight: 700"
                >
                  {{ item.doorwayName }}
                </a-row>
                <a-row class="list-item">
                  设备数量(进):
                  <span style="color: rgb(255, 255, 255)">{{
                    item.entryDeviceNum ? item.entryDeviceNum : "0"
                  }}</span>
                </a-row>
                <a-row class="list-item">
                  设备数量(出):
                  <span style="color: rgb(255, 255, 255)">
                    {{ item.exitDeviceNum ? item.exitDeviceNum : "0" }}
                  </span>
                </a-row>

                <a-row class="list-btn-group">
                  <!-- <router-link to="/labour/person/details"
                    ><a-button size="small">查看</a-button></router-link
                  > -->
                  <a-button
                    size="small"
                    @click="doorwayListDetails(item.id, '1')"
                    >查看</a-button
                  >
                  <a-dropdown>
                    <a-button size="small"
                      >更多<a-icon type="caret-down"
                    /></a-button>
                    <a-menu slot="overlay">
                      <a-menu-item
                        key="1"
                        @click="doorwayListDetails(item.id, '2')"
                      >
                        编辑
                      </a-menu-item>
                      <a-menu-item key="2" @click="deleteDoorway(item.id)">
                        删除
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </a-row>
              </a-col>
            </a-row>
          </a-row>
          <!-- <a-tag class="list-status">已登记</a-tag> -->
        </a-row>
      </a-col>
    </a-row>
    <!-- <section class="pagination-container">
      <a-pagination
        size="default"
        :total="10"
        show-size-changer
        show-quick-jumper
      />
    </section> -->
    <!--添加出入库 -->
    <a-drawer
      class="drawer_module"
      :title="modalTitle"
      placement="right"
      :destroyOnClose="true"
      :visible="deviceVisible"
      :width="400"
      @close="onClose"
      :maskClosable="true"
    >
      <section>
        <a-form :form="serviveForm" class="m-b-10" @submit="submitForm">
          <a-form-item
            label="出入口名称"
            :label-col="{ span: '7' }"
            :wrapper-col="{ span: '16', offset: '1' }"
          >
            <a-input
              :disabled="deviceDisabled"
              autocomplete="off"
              v-decorator="[
                'doorwayName',

                {
                  rules: [
                    { required: true, message: '请填写出入口名称' },
                    { max: 30, message: '最大输入30个字符' },
                  ],
                },
              ]"
            ></a-input>
          </a-form-item>
          <!-- 
          <a-row type="flex" justify="center">
            <a-col
              class="deviceType"
              v-for="(item, index) in deviceTypeList"
              :key="index"
              @click="changeDeviceType(item.deviceType)"
            >
              <span :class="item.deviceType == deviceType ? 'active' : ''">{{
                item.name
              }}</span>
            </a-col>
          </a-row> -->
          <a-form-item
            label="出入口类型"
            :label-col="{ span: '7' }"
            :wrapper-col="{ span: '16', offset: '1' }"
          >
            <a-select
              :disabled="deviceDisabled"
              placeholder="请选择"
              v-decorator="[
                'doorwayType',
                {
                  rules: [{ required: true, message: '请选择出入口类型' }],
                },
              ]"
            >
              <a-select-option value="0"> 人员出入 </a-select-option>
              <a-select-option value="1"> 车辆出入 </a-select-option>
            </a-select>
          </a-form-item>

          <a-form-item
            label="选择设备(进)"
            class="list-table-container list-table"
            :label-col="{ span: '7' }"
          >
            <span
              v-if="doorwayType != '1'"
              @click="addEnter('1')"
              style="
                float: right;
                font-size: 16px;
                margin-right: 20px;
                cursor: pointer;
              "
            >
              <a-icon type="plus-circle" />
            </span>
            <a-table
              :bordered="false"
              :data-source="enterSource"
              size="small"
              class="m-t-36"
              :columns="enterColumns"
              :pagination="false"
              v-decorator="[
                'entryDeviceIds',
                {
                  rules: [{ required: true, message: '请选择设备进口' }],
                },
              ]"
            >
              <template slot="operation" slot-scope="list, text">
                <a style="color: #529aff" @click="delEnterSorue(text.id)"
                  ><a-icon type="close-circle"
                /></a>
              </template>
            </a-table>
          </a-form-item>
          <a-form-item
            label="选择设备(出)"
            class="list-table-container list-table"
            :label-col="{ span: '7' }"
          >
            <span
              v-if="doorwayType != '1'"
              @click="addEnter('2')"
              style="
                float: right;
                font-size: 16px;
                margin-right: 20px;
                cursor: pointer;
              "
            >
              <a-icon type="plus-circle" />
            </span>
            <a-table
              :bordered="false"
              :data-source="leaveSource"
              size="small"
              class="m-t-36"
              :columns="enterColumns"
              :pagination="false"
              v-decorator="[
                'exitDeviceIds',
                {
                  rules: [{ required: true, message: '请选择设备出口' }],
                },
              ]"
            >
              <template slot="operation" slot-scope="list, text">
                <a style="color: #529aff" @click="delLeaveSorue(text.id)"
                  ><a-icon type="close-circle"
                /></a>
              </template>
            </a-table>
          </a-form-item>
        </a-form>
      </section>

      <!-- 模态框 -->
      <a-modal
        class="modal-container"
        :width="1000"
        :height="600"
        v-model="modelVisible"
        :footer="false"
        title="添加出入口ddd"
      >
        <a-row
          class="m-b-10 search-container"
          type="flex"
          justify="space-between"
        >
          <a-col :span="7" style="color: #4b80cb">
            <span class="m-r-8">设备名称:</span>
            <a-input v-model="modalDeviceName" placeholder="请输入" />
          </a-col>
          <a-col :span="7" class="m-r-18">
            <p class="m-r-8" style="margin: 0; color: #4b80cb">设备类型:</p>
            <!-- <a-select
              style="width: 100%"
              class="search-select"
              placeholder="请选择"
              @change="selectDeivceType"
              v-model="selectDeivceName"
            >
              <a-select-option value=""> 全部 </a-select-option>
              <a-select-option
                v-for="(item, index) in deviceTypeListData"
                :key="index"
                :value="item.id"
              >
                {{ item.typeName }}
              </a-select-option>
            </a-select> -->
            <a-select
              style="width: 100%"
              placeholder="请选择"
              @change="selectDeivceType"
              v-model="selectDeivceName"
            >
              <a-select-option value="1,2"> 全部 </a-select-option>
              <a-select-option value="1"> 人脸闸机 </a-select-option>
              <a-select-option value="2"> 车辆识别设备 </a-select-option>
            </a-select>
          </a-col>
          <a-col :span="7" class="m-t-18">
            <a-button class="m-r-8" html-type="submit" @click="checkDeivceList"
              >查询</a-button
            >
            <a-button @click="resetDeivceList" class="m-r-8">重置</a-button>
          </a-col>
        </a-row>

        <section class="list-table-container list-table">
          <a-table
            :rowSelection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }"
            :bordered="false"
            :data-source="deviceSource"
            size="small"
            class="m-t-36"
            :columns="deviceColumns"
            :pagination="false"
          >
            <template slot="typeId" slot-scope="list, text">
              <span>{{
                text.typeId == "1" ? "人脸闸机" : "车辆识别设备"
              }}</span>
            </template>
          </a-table>
        </section>

        <a-row class="form-btn-group m-t-18">
          <a-button class="btn-save" @click="saveDeviceData" html-type="submit"
            ><a-icon type="save" />保存</a-button
          >
          <a-button class="btn-cancel" @click="cancelModal">取消</a-button>
        </a-row>
      </a-modal>

      <div
        :style="{
          position: 'absolute',
          right: '10px',
          bottom: '20px',
          width: '100%',
          padding: '10px 16px',
          textAlign: 'right',
          zIndex: 1,
        }"
      >
        <a-button
          v-if="doorwayType != '1'"
          class="sub_button"
          type="primary"
          @click="save"
        >
          保存
        </a-button>
        <a-button class="close_button" @click="close"> 关闭 </a-button>
      </div>
    </a-drawer>
  </section>
</template>
<script>
const columns = [
  { title: "序号", dataIndex: "ind", align: "center" },
  { title: "设备名称", dataIndex: "name", align: "center" },
  { title: "发起人", dataIndex: "ind1", align: "center" },
  { title: "处理人", dataIndex: "ind2", align: "center" },
  { title: "验收人", dataIndex: "ind3", align: "center" },
  { title: "发起时间", dataIndex: "ind4", align: "center" },
  { title: "任务状态", dataIndex: "ind5", align: "center" },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  data() {
    return {
      modalTitle: "添加出入口",
      selectDeivceName: "",
      list: {
        current: 1,
        total: 0,
        // size: 12
      },
      deviceDisabled: false,
      columns,
      doorwayType: "",
      doorType: "", //出入口类型
      modalDeviceName: "",
      deviceVisible: false,
      deviceId: "",
      doorwayName: "",
      modelVisible: false,
      selectedRowKeys: [],
      enterselectedRowKeys: [],
      leaveselectedRowKeys: [],
      entryDeviceIds: [],
      exitDeviceIds: [],
      serviveForm: this.$form.createForm(this),
      forms: this.$form.createForm(this),
      doorListData: [],
      typeId: "1,2",
      deviceSource: [],
      enterType: "",
      deviceColumns: [
        { title: "名称", dataIndex: "deviceName", align: "center" },
        {
          title: "设备类型",
          dataIndex: "typeId",
          align: "center",
          scopedSlots: { customRender: "typeId" },
        },
        {
          title: "位置",
          dataIndex: "location",
          align: "center",
        },
      ],
      enterColumns: [
        { title: "设备名", dataIndex: "deviceName", align: "center" },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ],
      enterSource: [],
      leaveSource: [],
      enterForm: this.$form.createForm(this),
      deviceTypeListData: [], //设备类型数据
      delEnterID: [], //删除进口设备的id
      selectID: [],
    };
  },
  created() {
    this.doorwayList();
  },
  methods: {
    // 车辆列表
    doorwayList() {
      let params = {
        doorwayName: this.doorwayName,
        doorwayType: this.doorType,
      };
      this.$api.doorwayList(params).then((res) => {
        if (res.code == "200") {
          if (res.data && res.data.length > 0) {
            res.data.forEach((item) => {
              item.typeImg =
                item.doorwayType == "0"
                  ? require("../../../assets/image/header/personType.png")
                  : require("../../../assets/image/header/carType.png");
            });
          }
          this.doorListData = res.data ? res.data : [];
        }
      });
    },
    // 查询
    submitForm(e) {
      e && e.preventDefault();
      let formVal = this.enterForm.getFieldsValue();
      this.doorwayName = formVal.doorwayName;
      this.doorType = formVal.doorwayType;
      this.doorwayList();
    },
    // 查看详情
    doorwayListDetails(id, type) {
      this.$api.doorwayListDetails(id).then((res) => {
        if (res.code == "200") {
          this.deviceId = id;
          this.deviceVisible = true;
          let data = res.data;
          this.doorwayType = type;
          this.modalTitle = type == "1" ? "查看出入口" : "修改出入口";
          console.log(data.doorwayType, "data.doorwayType");
          this.$nextTick(() => {
            this.serviveForm.setFieldsValue({
              doorwayType: data.doorwayType + "",
              doorwayName: data.doorwayName,
            });

            if (type == "1") {
              this.enterColumns = [
                { title: "设备名", dataIndex: "deviceName", align: "center" },
              ];
              this.deviceDisabled = true;
            } else {
              this.deviceDisabled = false;
              this.enterColumns = [
                { title: "设备名", dataIndex: "deviceName", align: "center" },
                {
                  title: "操作",
                  dataIndex: "operation",
                  align: "center",
                  scopedSlots: { customRender: "operation" },
                },
              ];
            }

            // 进场设备
            let entryDeviceIds = [];
            if (data.entryDevices && data.entryDevices.length > 0) {
              data.entryDevices.forEach((item, index) => {
                item.key = index + 1;
                item.deviceName = item.device_name;
                entryDeviceIds.push(item.id);
              });
              this.enterSource = data.entryDevices;
            }

            // 出场设备
            let exitDeviceIds = [];
            if (data.exitDevices && data.exitDevices.length > 0) {
              data.exitDevices.forEach((item, index) => {
                item.key = index + 1;
                item.deviceName = item.device_name;
                exitDeviceIds.push(item.id);
              });
              this.leaveSource = data.exitDevices;
            }

            // 回填表单
            this.$nextTick(() => {
              this.serviveForm.setFieldsValue({
                entryDeviceIds: entryDeviceIds,
                exitDeviceIds: exitDeviceIds,
              });
            });
          });
        }
      });
    },
    // 删除
    deleteDoorway(id) {
      this.$api.deleteDoorway(id).then((res) => {
        if (res.code == "200") {
          this.doorwayList();
        }
      });
    },
    // 重置
    reset() {
      this.doorwayName = "";
      this.doorType = "";
      this.enterForm.resetFields();
      this.doorwayList();
    },
    // 切换类型
    changeDeviceType(type) {
      this.deviceType = type;
    },
    // 选择设备类型
    selectDeivceType(val) {
      console.log(val);
      this.typeId = val;
      this.selectDeivceName = val;
    },
    // 添加出入库 查询设备数据
    checkDeivceList() {
      this.getDeviceLsit();
    },
    // 添加出入库 重置设备数据
    resetDeivceList() {
      this.modalDeviceName = "";
      this.typeId = "1,2";
      this.selectDeivceName = "全部";
      this.getDeviceLsit();
    },

    // 选择添加设备
    onSelectChange(selectedRowKeys) {
      // console.log(selectedRowKeys);
      this.selectedRowKeys = selectedRowKeys;
      if (this.enterType == "1") {
        this.enterselectedRowKeys = selectedRowKeys; //进

        this.$nextTick(() => {
          this.serviveForm.setFieldsValue({
            entryDeviceIds: this.enterselectedRowKeys,
          });
        });
      }
      if (this.enterType == "2") {
        this.leaveselectedRowKeys = selectedRowKeys; //出
        this.$nextTick(() => {
          this.serviveForm.setFieldsValue({
            exitDeviceIds: this.leaveselectedRowKeys,
          });
        });
      }
    },
    // 设备列表
    getDeviceLsit() {
      let params = {
        deviceName: this.modalDeviceName,
        typeId: this.typeId,
      };
      this.$api.getDevice(params).then((res) => {
        console.log(res);
        if (res.code == "200") {
          if (res.data && res.data.length > 0) {
            let list = res.data || [];

            let datalist = list.filter((item) => {
              item.key = item.id;
              // console.log(
              //   !this.selectID.includes(item.id),
              //   !item.doorwayId || this.delEnterID.includes(item.id)
              // );
              // return (
              //   !this.selectID.includes(item.id) &&
              //   (!item.doorwayId || this.delEnterID.includes(item.id))
              // );
              return (
                !this.selectID.includes(item.id)
              );
            });
            // console.log(datalist);
            this.deviceSource = datalist;

            // let arr = res.data.filter((item) => {
            //   if (!item.doorwayId) return item;
            // });
            // arr.forEach((item) => {
            //   item.key = item.id;
            // });
            // let data = arr ? arr : [];

            // // res.data.forEach((item) => {
            // //   item.key = item.id;
            // // });
            // // let data = res.data ? res.data : [];

            // let total = this.enterSource.concat(this.leaveSource); //汇总所有已绑定设备

            // let dataList = this.array_diff(data, total);

            // this.deviceSource = dataList;
          }
          // this.deviceSource = res.data ? res.data : [];
          // let data = res.data ? res.data : [];
          // let total = this.enterSource.concat(this.leaveSource);
          // console.log(total, "totaltotal");
          // let dataList = this.array_diff(data, total);
          // console.log(dataList, "dataList");
          // this.deviceSource = dataList;
        }
      });
    },
    // 添加出入口
    andEnter() {
      //this.getDeviceLsit();
      this.deviceVisible = true;
    },

    // 设备类型
    // deviceTypeList() {
    //   this.$api.deviceTypeList().then((res) => {
    //     if (res.code == "200") {
    //       this.deviceTypeListData = res.data;
    //     }
    //   });
    // },
    // 保存设备数据
    saveDeviceData() {
      // console.log(this.selectedRowKeys, this.deviceSource);
      let arr = [];

      if (this.enterType == "1") {
        this.deviceSource.forEach((item) => {
          this.enterselectedRowKeys.forEach((val) => {
            if (item.id == val) {
              this.selectID.push(item.id);
              this.enterSource.push(item);
            }
          });
        });
        this.modelVisible = false;
      }

      if (this.enterType == "2") {
        this.deviceSource.forEach((item) => {
          this.leaveselectedRowKeys.forEach((val) => {
            if (item.id == val) {
              this.selectID.push(item.id);
              this.leaveSource.push(item);
            }
          });
        });
        // this.leaveSource = arr;
        this.modelVisible = false;
        console.log(arr);
      }
    },
    // 删除进口
    delEnterSorue(id) {
      // 修改的时候删除设备
      if (this.doorwayType == "2") {
        // let entryDeviceIdsList = this.enterSource.filter((item) => {
        //   if (item.id != id) return item.id;
        // });
        // 删除进口

        // this.enterSource = this.enterSource.filter()
        this.delEnterID.push(id);

        let entryDeviceIdsList = [];
        this.enterSource.forEach((item) => {
          if (item.id != id) {
            entryDeviceIdsList.push(item.id);
          }
        });

        this.$nextTick(() => {
          this.serviveForm.setFieldsValue({
            entryDeviceIds: entryDeviceIdsList,
          });
        });
      } else {
        // 新增的时候删除设备
        this.enterselectedRowKeys = this.enterselectedRowKeys.filter((item) => {
          if (item != id) return item;
        });
        this.$nextTick(() => {
          this.serviveForm.setFieldsValue({
            entryDeviceIds: this.enterselectedRowKeys,
          });
        });
      }
      // 过滤已选择的
      let map = this.selectID.filter((item) => {
        if (item != id) return item;
      });
      this.selectID = map;

      this.enterSource = this.enterSource.filter((item) => {
        if (item.id != id) return item;
      });
    },
    // 删除出口
    delLeaveSorue(id) {
      // 修改的删除
      if (this.doorwayType == "2") {
        // let exitDeviceIdsList = this.leaveSource.filter((item) => {
        //   if (item.id != id) return item;
        // });
        this.delEnterID.push(id);
        let exitDeviceIdsList = [];
        this.leaveSource.forEach((item) => {
          if (item.id != id) {
            exitDeviceIdsList.push(item.id);
          }
        });
        this.$nextTick(() => {
          this.serviveForm.setFieldsValue({
            exitDeviceIds: exitDeviceIdsList,
          });
        });
      } else {
        // 新增的时候删除设备
        this.leaveselectedRowKeys = this.leaveselectedRowKeys.filter((item) => {
          if (item != id) return item;
        });
        this.$nextTick(() => {
          this.serviveForm.setFieldsValue({
            exitDeviceIds: this.leaveselectedRowKeys,
          });
        });
      }
      // 过滤已选择的
      let map = this.selectID.filter((item) => {
        if (item != id) return item;
      });
      this.selectID = map;
      this.leaveSource = this.leaveSource.filter((item) => {
        if (item.id != id) return item;
      });
      // this.leaveselectedRowKeys = this.leaveselectedRowKeys.filter((item) => {
      //   if (item != id) return item;
      // });
    },
    // 设备添加保存
    save() {
      this.serviveForm.validateFields((err, values) => {
        if (!err) {
          if (this.doorwayType == "2") {
            let params = {
              doorwayType: parseInt(values.doorwayType),
              doorwayName: values.doorwayName,
              entryDeviceIds: values.entryDeviceIds.join(","),
              exitDeviceIds: values.exitDeviceIds.join(","),
              id: this.deviceId,
            };
            //console.log(params, values, "params");
            this.$api.updateDoorway(params).then((res) => {
              if (res.code == "200") {
                this.deviceVisible = false;
                this.doorwayList();
                this.enterSource = [];
                this.leaveSource = [];
              }
            });
          } else {
            let params = {
              doorwayType: parseInt(values.doorwayType),
              doorwayName: values.doorwayName,
              entryDeviceIds: values.entryDeviceIds,
              exitDeviceIds: values.exitDeviceIds,
            };

            this.$api.addDoorway(params).then((res) => {
              if (res.code == "200") {
                this.deviceVisible = false;
                this.doorwayList();
                this.enterSource = [];
                this.leaveSource = [];
              }
            });
          }
        }
      });
    },

    // 取消
    cancelModal() {
      this.modelVisible = false;
    },
    // 关闭弹窗
    close() {
      this.enterSource = [];
      this.leaveSource = [];
      this.selectID = []; //清空
      this.delEnterID = []; //清空
      this.deviceVisible = false;
      this.deviceDisabled = false;
      this.selectedRowKeys = [];
      this.leaveselectedRowKeys = [];
      this.enterselectedRowKeys = [];
      this.doorwayType = "";
      this.modalTitle = "添加出入口";

      this.enterColumns = [
        { title: "设备名", dataIndex: "deviceName", align: "center" },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ];
    },
    //关闭弹窗
    onClose() {
      this.enterSource = [];
      this.leaveSource = [];
      this.selectID = []; //清空
      this.delEnterID = []; //清空
      this.deviceVisible = false;
      this.deviceDisabled = false;
      this.modalTitle = "添加出入口";
      this.selectedRowKeys = [];
      this.leaveselectedRowKeys = [];
      this.enterselectedRowKeys = [];
      this.doorwayType = "";

      this.enterColumns = [
        { title: "设备名", dataIndex: "deviceName", align: "center" },
        {
          title: "操作",
          dataIndex: "operation",
          align: "center",
          scopedSlots: { customRender: "operation" },
        },
      ];
    },
    // 添加设备
    addEnter(type) {
      this.getDeviceLsit();
      // this.deviceTypeList();
      this.modelVisible = true;
      this.enterType = type;
      if (type == "1") {
        // 修改
        if (this.doorwayType == "2") {
          let arr = [];
          this.enterSource.forEach((item) => {
            arr.push(item.id);
          });

          this.selectedRowKeys = arr;
        } else {
          this.selectedRowKeys = this.enterselectedRowKeys;
        }
      }
      if (type == "2") {
        // 修改
        if (this.doorwayType == "2") {
          let arr = [];
          this.leaveSource.forEach((item) => {
            arr.push(item.id);
          });
          this.selectedRowKeys = arr;
        } else {
          this.selectedRowKeys = this.leaveselectedRowKeys;
        }
      }
      //  let params={
      //   deviceName:this.modalDeviceName,
      //   typeId:this.typeId,
      // }
    },
    // 数组去重
    array_diff(a, b) {
      for (var i = 0; i < b.length; i++) {
        for (var j = 0; j < a.length; j++) {
          if (a[j].id == b[i].id) {
            a.splice(j, 1);
            j = j - 1;
          }
        }
      }
      return a;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.ant-calendar-picker-input {
  width: 100%;
}
/deep/ .ant-calendar-picker-icon {
  color: #93bbf3 !important;
}
/deep/ .has-error .ant-input:hover {
  background: #345e98;
}
.list-wrap {
  margin-left: 20px;
  .list-image {
    width: 35%;
  }
  .list-image img {
    border: 0;
    width: 68%;
  }
  button {
    background: #3f72ba !important;
  }
}
/deep/.ant-table-tbody > tr.ant-table-row-selected td {
  color: inherit;
  background: #1c3d6c;
}
.deviceType {
  width: 50%;
  text-align: center;
  color: #93bbf3;
  cursor: pointer;
  margin-bottom: 10px;
  .active {
    border-bottom: 1px solid #93bbf3;
    padding-bottom: 5px;
  }
}
</style>